@import '../../assets/styles//mixin.scss';
@import '../../assets/styles/const.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  margin-right: 32px;
  transition: all 0.4s ease 0s;
  height: 352px;
  width: 268px;
  cursor: pointer;
  overflow: hidden;
  border-radius: $primary-border;

  &:hover {
    transform: translateY(-10px);
  }

  p {
    margin-bottom: 0;
    letter-spacing: 2px;
  }
}

.cover,
.coverNoTime {
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  text-align: initial;
  font-size: 18px;
  background-color: #fff;
  border-radius: $primary-border;
}

.cover {
  padding: 36px 12px 5px 12px;
}

.coverNoTime {
  padding: 36px 12px 12px 12px;
}

.name,
.price,
.highPriceContainer {
  color: #222222;
}

.name {
  @include no-wrap;

  margin-top: 4px !important;
  margin-bottom: 4px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.price {
  margin: 6px 0;
  color: #3ddcff;
}

.highPriceContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;

  .hightPriceContent {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #727993;

    .num {
      max-width: 60px;
      @include no-wrap();
    }
  }
}

.imgContainer {
  overflow: auto;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 268px;
  background-color: #e5e5e8;
  border-radius: $primary-border;

  .img {
    background-color: #f8f8f8;
    object-fit: contain;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.clockText {
  padding-top: 12px;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #222222;
  text-align: right;

  .clock {
    width: 10px !important;
    height: 10px !important;
    vertical-align: middle;
  }

  > span {
    vertical-align: middle;
    margin-left: 5px;
  }
}
