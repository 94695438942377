// .infoModal {
//     width: 960px;
// }

:global {
  .ant-modal-content {
    border-radius: 12px !important;
  }
}

.title {
  font-weight: 600;
  font-size: 24px;
}

.item {
  display: flex;
  margin-bottom: 48px;
  line-height: 48px;
  text-align: center;

  p {
    margin: 0;
    line-height: 24px;
  }

  .itemIndex {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    border: 2px solid #000000;
  }

  .itemInfo {
    flex: 1;
    margin-left: 50px;
    text-align: left;

    .main {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -1px;
      color: #222222;
    }

    .desc {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #222222;
      opacity: 0.7;
    }
  }

  .activeButton,
  .nextButton {
    width: 222px;
    height: 48px;
    margin-right: 50px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #ffffff;
    border: none;
    outline: none;


  }

  .activeButton {
    background-color: #4737ff;

    &:hover {
      opacity: 0.8;
      // color: currentColor;
    }
  }

  .nextButton {
    background-color: inherit;
    border: 0;
    color: #000000;
  }
}