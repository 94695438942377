@import './const.scss';
@import './mixin.scss';

/* 字体 */
@font-face {
    font-display: auto;
    font-family: PingFangS;
    font-weight: normal;
    src: url('../fonts/FiraSans-Regular.ttf');
}

@font-face {
    font-display: auto;
    font-family: PingFangS;
    font-weight: 500;
    src: url('../fonts/FiraSans-Medium.ttf');
}

@font-face {
    font-display: auto;
    font-family: PingFangS;
    font-weight: 600;
    src: url('../fonts/FiraSans-SemiBold.ttf');
}

body {
    font-family: PingFangS, 'HelveticaNeue', Helvetica, Arial, sans-serif !important;
}

/* 浮动 */
.fl {
    float: left;
}

.fr {
    float: right;
}

.clearfix::after {
    clear: both;
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
}

.bold {
    font-weight: bold;
}

// 适配vw单位
$vw_base: 1920;

@function vw($px) {
    @return ($px / $vw_base) * 100vw;
}

//适配vh单位
$vh_base: 1080;

@function vh($px) {
    @return ($px / $vh_base) * 100vh;
}

/* 水平对齐 */
// 右对齐
.tr {
    text-align: right;
}

// 居中对齐
.tc {
    text-align: center;
}

/**
* 表格公共样式
*/

.table {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    .tc {
        display: flex;
        justify-content: center;
    }

    &Th {
        height: 76px;
        line-height: 76px;
        border-top: 1px solid $neutral-color1;
        border-bottom: 1px solid $neutral-color1;
        box-sizing: border-box;
        font-size: 18px;
        font-weight: 600;
        color: $txt-color3;
    }

    &Tr {
        height: 83px;
        line-height: 83px;

        &:nth-child(2n + 1) {
            height: 72px;
            line-height: 72px;
            background-color: $neutral-color3;
        }

        .event {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: 600;

            &Ic {
                width: 20px;
                height: 20px;
                margin-right: 7px;
                margin-left: 10px;
            }
        }

        .account {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $primary-bg;

            &Ic {
                width: 24px;
                height: 24px;
                background-color: #d8d8d8;
                margin-right: 8px;
                border-radius: 50%;
            }
        }

        .date {
            font-size: 16px;
        }
    }
}

.cursor {
    cursor: pointer;
}

:global {
    .ant-spin {
        max-height: fit-content !important;
    }
}