.container {
  padding: 24px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #dedcdc;
}

.content {
  display: flex;
  align-items: center;
}

.imgContainer {
  display: inline-block;
  margin-left: 8px;
  border-radius: 50%;
}

.userHead {
  width: 104px;
  height: 104px;
  border: 6px solid #fff;
  border-radius: 50%;
}

.desc {
  display: flex;
  flex-direction: column;
  width: 216px;
  margin-left: 72px;
  margin-right: 32px;

  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #222222;
    opacity: 0.7;
    margin-bottom: 5px;
  }

  >p {
    margin: 0;
    font-weight: 500;
  }

  .descInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #222;

    span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      width: 24px;
      height: 24px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .edit {
    font-size: 20px;
  }
}

.points {
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #222222;
    opacity: 0.7;
    margin-bottom: 5px;
  }

  .value {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #222222;
  }
}

.right {
  display: flex;
  justify-content: space-between;
  margin-left: 97px;
  letter-spacing: -1px;
  color: #222222;

  .rightLabel {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  .rightRow {
    display: flex;
    flex-direction: column;
    width: 250px;
  }

  .rightValue {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
  }
}