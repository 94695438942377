.cardContent {
  width: 1168px;
  margin: 72px auto 600px;

  .cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .cardCreate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 572px;
    height: 228px;
    background: #ECECED;
    border: 2px dashed #979797;
    box-sizing: border-box;
    border-radius: 12px 0px 0px 0px;
  }

  .cardCreateButton {
    width: 158px;
    height: 32px;
    color: #fff;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 16px;
    border: none;
    outline: none;
    line-height: 32px;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .divier {
    margin-top: 16px;
    margin-bottom: 40px;
    border: 1px solid #DEDCDC;
  }
}