@import '../../../../assets/styles/const.scss';
@import '../../../../assets/styles/mixin.scss';

.item {
  display: flex;
  flex-direction: column;
  padding: 0 24px 32px;
  @include one-px-border($border-color);

  label {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -1px;
    color: $primary-font-color;
  }

  .line {
    display: flex;
    align-items: baseline;
    margin-top: 20px;
    margin-bottom: 48px;
    // padding-left: 28px;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -1px;
    color: $primary-font-color;

    .inputBox {
      position: relative;
      margin-right: 10px;

      .placeholder {
        display: flex;
        align-items: baseline;
        position: absolute;
        right: 0;
        top: 0px;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: rgba(34, 34, 34, 0.3);

        .num {
          margin-left: 4px;
          font-size: 48px;
          line-height: 56px;
        }
      }

      .input {
        input {
          font-weight: 600;
          font-size: 48px;
          line-height: 56px;
          text-align: right;
        }
      }
    }
  }

  .total {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;
    letter-spacing: -1px;
    color: $txt-color;

    .txt1 {
      font-weight: 600;
      font-size: 48px;
      line-height: 56px;
    }

    .txt2 {
      margin-top: 24px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.item1 {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -1px;
  color: $txt-color;
  @include one-px-border($border-color);
}

.checkList {
  padding: 0 24px;
  margin-top: 16px;
}