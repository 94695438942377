.upLoadSeciton {
  width: 100%;
  height: 100%;

  :global {
    .ant-upload {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .imgUpload {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(0deg, #ebebeb, #ebebeb);
    border: 2px dashed #979797;
    box-sizing: border-box;
    border-radius: 12px 0px 0px 0px;
    position: relative;
    overflow: hidden;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    .icon {
      width: 48px;
      height: 48px;
      margin: 0 auto 45px;
    }

    .iconError {
      margin-bottom: 20px;
    }

    .error {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #c8020e;
      opacity: 0.7;
      margin-bottom: 8px;
    }

    .tips {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: rgba(34, 34, 34, 0.7);
      opacity: 0.7;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .errorImgUpload {
    border-color: #c8020e;
  }
}

.newStyles {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.childStyles {
  top: auto;
  bottom: 0;
}