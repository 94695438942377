.container {

    width: 100%;
    margin-top: 32px;
    margin-bottom: 145px;

    .cards {
        width: 984px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }


}

.noData {
    margin: 0 auto;
}