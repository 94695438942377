@import '../../assets/styles/const.scss';
@import '../../assets/styles//mixin.scss';

.container {
  position: relative;
  height: 352px;
  width: 268px;
  margin-right: 32px;
  border-radius: $primary-border;

  &:hover {
    cursor: pointer;
  }

  &:last-of-type {
    margin-right: 0;
  }

  p {
    margin-bottom: 0;
    line-height: 22px;
    letter-spacing: 2px;
  }

  .name {

    @include no-wrap;

    margin-bottom: 4px;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;

    color: #222222;
  }

}

.coverTrend,
.coverHot {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  font-size: 18px;
  width: 100%;
  padding-left: 16px;
  background-color: #fff;
}

.coverTrend {
  padding-top: 43px;
  padding-bottom: 30px;
}

.coverHot {
  padding-top: 21px;
  padding-bottom: 12px;

  .clockText {
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #222222;
    text-align: right;
    margin-right: 12px;

    .clock {
      width: 10px !important;
      height: 10px !important;
      vertical-align: middle;
    }

    >span {
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}


.price {
  display: flex;
  justify-content: space-between;

  .priceTitle {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #222222;
  }

  .priceNumber {
    padding-right: 12px;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #727993;
  }
}

.imgContainer {
  overflow: auto;
  z-index: 9;
  position: absolute;
  width: 100%;
  height: 268px;
  display: flex;
  justify-content: center;
  background-color: #e5e5e8;
  border-radius: $primary-border;

  .img {
    object-fit: contain;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    background-color: #F8F8F8;
  }
}