@import '../../../../assets/styles/const.scss';

.container {
  margin-top: 56px;

  .box {
    display: flex;
    width: 100%;
    margin-top: $second-margin;
  }

  .card {
    display: flex;
    justify-content: center;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}