.container {
  display: flex;
  margin: 0;

  >li {
    margin-right: 24px;
    letter-spacing: 4px;
    opacity: 0.3;
    font-size: 14px;
    font-weight: 500;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .active {
    color: #1434e1;
    opacity: 1;
  }
}