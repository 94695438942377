.CreateCollectibleSetion {
  width: 574px;
  margin: 0 auto;

  .createContent {
    display: flex;
    width: 100%;
    padding: 32px 0 24px;
    box-sizing: border-box;
    margin: 0 auto;

    .imgInfoSection {
      margin-right: 24px;
    }

    .collectionSection {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;

      .collectableSelect {
        position: absolute;
        right: 0;
        top: 0;
      }

      .types {
        display: flex;
        flex-direction: row;
        width: 100%;
        box-sizing: border-box;
        flex-wrap: nowrap;
        overflow-x: auto;

        .cards {
          display: flex;

          .card {
            width: 100%;
            height: 228px;
            padding: 32px 24px;
            background: #ffffff;
            border: 2px solid #222222;
            box-sizing: border-box;
            border-radius: 12px;

            .title {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              letter-spacing: -1px;
              color: #222222;
              margin-bottom: 15px;
            }

            .value {
              font-weight: normal;
              font-size: 12px;
              line-height: 18px;
              color: rgba(34, 34, 34, 0.7);
            }
          }
        }
      }


    }
  }

  .modalContent {
    width: 100%;
    display: flex;
    flex-direction: column;

    .priceDiv {
      position: relative;
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 0 24px 32px;
      box-sizing: border-box;
      border-bottom: 1px solid #dedcdc;

      .priceTitle {
        position: absolute;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -1px;
        color: #222222;
        margin-bottom: 20px;
      }

      .priceSection {
        display: flex;
        justify-content: flex-end;
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        text-align: right;
        letter-spacing: -1px;
        color: #222222;

        .priceUint {
          margin-left: 10px;
        }
      }

      .priceSelect {
        margin-left: auto;
        margin-top: 12px;

        .selectStyle {
          width: 124px;
          height: 32px;
        }

        .selectStyle {
          :global {
            .ant-select {
              width: 124px;
              height: 32px;

              &-selector {
                height: 32px !important;
                padding: 8px 12px !important;
              }
            }
          }
        }
      }
    }

    .balance {
      width: 100%;
      height: 66px;
      line-height: 66px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #dedcdc;
      padding: 0 24px;
      box-sizing: border-box;

      .balanceTitle {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -1px;
        color: #484848;
      }

      .balanceValue {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -1px;
        color: #484848;
      }
    }

    .tips {
      display: flex;
      flex-direction: column;
      padding: 24px;
      padding-bottom: 6px;
      box-sizing: border-box;

      .tip {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: rgba(34, 34, 34, 0.7);
        opacity: 0.7;
        margin-top: 16px;
      }

      .tip:first-child {
        margin-top: 0;
      }
    }
  }

  .imgStyle {
    height: 228px;
    width: 228px;
  }

  .divider {
    border: 1px solid #DEDCDC;
  }

  .infos {
    box-sizing: border-box;

    .names {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      .inputSection {
        width: 248px;
      }

      .category {
        display: flex;

        .text {
          width: 113px;
          text-align: left;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          letter-spacing: 4px;
          text-transform: uppercase;
          color: #222222;
        }

        .categorySelect,
        .selectStyle {
          width: 124px;
          height: 32px;
        }

        .selectStyle {
          :global {
            .ant-select {
              width: 124px;
              height: 32px;

              &-selector {
                height: 32px !important;
                padding: 8px 12px !important;
              }
            }
          }
        }
      }
    }

    .description {
      width: 100%;
      margin-top: 24px;
    }

    .property {
      max-width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;


      .propertyItem {
        width: 180px;
        margin-top: 24px;
        margin-right: 16px;
      }

      .propertyItem:nth-child(3n+3) {
        margin-right: 0;
      }
    }

    .addButton {
      width: 124px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border: 1px solid #dedcdc;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 4px;
      text-transform: uppercase;
      color: #222222;
      margin-top: 32px;
      margin-bottom: 40px;
      cursor: pointer;
    }

    .tips {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #222222;
      opacity: 0.7;

    }

    .submit {
      width: 222px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background: #4737ff;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      letter-spacing: 4px;
      text-transform: uppercase;
      color: #ffffff;
      margin: 16px auto 91px;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }

    .noSubmit {
      background-color: rgba(34, 34, 34, 0.3);
      color: #ffffff;
      cursor: no-drop;
    }

  }

  .checkList {
    padding: 0 24px;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: rgba(34, 34, 34, 0.7);
    opacity: 0.7;
  }
}