@import '../../../../assets/styles/const.scss';
@import '../../../../assets/styles/mixin.scss';

.historyCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  color: rgba(34, 34, 34, 0.7);
  font-size: 14px;
  line-height: 18px;

  .left {
    display: flex;
    align-items: center;
  }

  .avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #e5e5e5;
  }

  .info {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 130px;
    margin-left: 64px;

    .type {
      width: fit-content;
      padding: 0 12px;
      margin: 0;
      color: $primary-font-color;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 2px;
      background: rgba(34, 34, 34, 0.1);
      border-radius: 16px;
    }

    .addressBox {
      margin: 8px 0 0;
      @include no-wrap();

      .address {
        color: $active-color;
      }
    }
  }

  .price {
    margin: 0;
    color: $primary-font-color;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  .timeBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p {
      margin: 0;

      &:last-child {
        margin: 10px 0 0;
      }
    }
  }
}
