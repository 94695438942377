.CreateSetion {
  width: 574px;
  margin: 32px auto 0;

  .createContent {
    width: 574px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin: 0 auto;

    .infos {
      .names {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: baseline;
        margin-top: 24px;

        .inputSection {
          width: 248px;
        }

        .category {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .text {
            width: 113px;
            font-family: Fira Sans;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            /* or 171% */

            letter-spacing: 4px;
            text-transform: uppercase;

            color: rgba(34, 34, 34, 0.7);

          }

          .categorySelect,
          .selectStyle {
            width: 124px;
            height: 32px;
          }

          .selectStyle {
            :global {
              .ant-select {
                width: 124px;
                height: 32px;

                &-selector {
                  height: 32px !important;
                  padding: 8px 12px !important;
                }
              }
            }
          }
        }
      }

      .description {
        width: 100%;
        margin-top: 24px;
      }


    }
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -1px;
    color: #222222;
    margin-bottom: 8px;
  }

  .describe {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: rgba(34, 34, 34, 0.7);
    opacity: 0.7;
    margin-bottom: 16px;
  }

  .submit {
    width: 222px;
    height: 48px;
    margin: 0 auto 318px;
    line-height: 48px;
    text-align: center;
    background: #4737ff;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #ffffff;


    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .divider {
    margin-bottom: 12px;
    border: 1px solid #DEDCDC;
  }

  .submitDivider {
    margin-top: 32px;
    margin-bottom: 24px;
    border: 1px solid #DEDCDC;
  }
}