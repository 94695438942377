.container {
  position: relative;
  display: flex;
  // flex-wrap: wrap;
  min-height: 820px;
  margin-bottom: 145px;

  .content {
    width: 1007px;
    min-height: 820px;
    padding-left: 21px;
    padding-top: 24px;
  }

  .cards {
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    min-height: 200px;
  }

  .divider {
    margin: 72px 0;
    border: 1px solid #dedcdc;
  }

  .collectsContainer {
    display: flex;
    flex-wrap: wrap;
    width: 1024px;
    // margin: 0 auto;
  }

  .refresh {
    position: absolute;
    top: -24px;
    left: 183px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1434e1;

    .refreshName {
      font-family: Fira Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height, or 129% */

      letter-spacing: 4px;
      text-transform: uppercase;
      display: block;
      margin-left: 5px;
    }

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .divier {
    border-bottom: 1px solid #dedcdc;
    margin: 16px 0 40px;
  }
}

.nftCards {
  width: 100%;
}

.noData {
  margin-top: 20%;
  font-size: 20px;
}
