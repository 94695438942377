@import '~antd/dist/antd.css';

/* 公共样式 */
.my-player {
	z-index: 9;
	height: 100% !important;
	border-radius: 12px;
}

.my-player .video-react-big-play-button {
	display: none;
}

.video-react-control-bar {
	border-radius: 12px;
}

/* 页面头部描述标题 */
.page-desc {
	margin-top: 32px;
	margin-bottom: 12px;
	color: rgba(34, 34, 34, 0.7);
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 4px;
}

.page-title {
	max-width: 600px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 600;
	font-size: 40px;
	line-height: 50px;
	letter-spacing: -1px;
	color: #222222;

}

/* antd样式更改 */
.ant-modal-header {
	border-radius: 12px 12px 0 0;
}