.layout {
  display: flex;
  flex-direction: column;
  padding-top: 87px;
  max-width: 1920px;
  min-width: 1360px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #f4f4f4;

  .pageCon {
    width: 100%;
  }
}