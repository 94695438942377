.title {
  margin: 0;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -1px;
  color: #222222;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoModal {
  border-radius: 12px;
}

.container {
  display: flex;
  flex-direction: column;

  .info {
    margin: 0;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -1px;
    color: #484848;
  }

  .load {
    margin-top: 20px;
  }

  .img {
    height: 46px;
    width: 46px;
    margin: 28px auto;
    animation: turn 1s linear infinite;
  }
  .imgSuc {
    height: 46px;
    width: 46px;
    margin: 28px auto;
  }
  .desc {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -1px;
    color: #1434e1;
  }
}

.cancelContainer {
  width: 632px;
  text-align: left;

  .cancel {
    display: block;
    width: 222px;
    height: 48px;
    margin: 48px auto 0;
    background-color: #4737ff;
    color: #fff;
    text-align: center;
    border: none;
    outline: none;
    &:hover {
      opacity: 0.8;
      color: currentColor;
    }
  }
}

@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }

  25% {
    -webkit-transform: rotate(90deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  75% {
    -webkit-transform: rotate(270deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
