@import '../../assets/styles/const.scss';

.container {
  width: 568px;

  &:hover {
    cursor: pointer;
  }

  .img {
    margin-bottom: 16px;
    height: 226px;
    width: 100%;

    background-color: #eee;

    >img {
      border-radius: $primary-border;
    }
  }

  .text {
    font-size: 18px;
    line-height: 28px;
    opacity: 0.7;
  }
}