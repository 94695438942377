@import '@/assets/styles/index.scss';

.gameRules {
  width: 100%;
  overflow: hidden;
  min-height: 800px;

  .grContent {
    display: flex;
    flex-direction: column;
    width: 1084px;
    margin: 0 auto;
    margin-top: 48px;

    .rulesItem {
      display: flex;
      flex-direction: column;

      .rulesTitle {
        text-align: center;
        margin-bottom: 26px;
        font-size: 20px;
        font-weight: 600;
        color: $txt-color1;
        line-height: 30px;
      }

      .rulesDec {
        margin-bottom: 48px;
        text-indent: 2em;

        div {
          color: $txt-color1;
          font-size: 18px;
          font-weight: normal;
          line-height: 30px;
        }

        .ques {
          font-weight: 600;
          margin-top: 12px;
        }
      }
    }
  }
}