@import '../../../../../../assets/styles/const.scss';
@import '../../../../../../assets/styles/mixin.scss';

.category {
  z-index: 9;
  display: inline-block;
  position: sticky;
  padding-top: 24px;
  top: 100px;
  max-height: 860px;
  font-weight: 500;
  min-width: 160px;
  max-height: 820px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  &Item {
    margin-top: 96px;

    &:first-child {
      margin-top: 0;
    }

    .title {
      margin-bottom: 36px;
      color: $primary-font-color;
      font-size: 20px;
      line-height: 28px;
      text-align: left;
      letter-spacing: -1px;
    }

    .item {
      @include no-wrap;

      width: 160px;
      margin-bottom: 14px;
      color: rgba(34, 34, 34, 0.3);
      font-size: 14px;
      line-height: 24px;
      text-align: left;
      letter-spacing: 4px;
      cursor: pointer;

      &:hover {
        color: $active-color;
      }

      &Ac {
        color: $active-color;
      }

      .triIc {
        width: 12px;
        height: 6px;
        margin-left: 6px;
      }
    }

    .chileItem {
      margin-bottom: 16px;
      font-size: 12px;
      line-height: 14px;

      &:last-child {
        margin-bottom: 32px;
      }
    }
  }
}
