@import '../../assets/styles/const.scss';

.explorePage {
  width: 1168px;
  padding-bottom: 200px;

  margin: 0 auto;

  .content {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 8px;

    .con {
      position: relative;
      width: 984px;

      /*隐藏滚轮*/
      ::-webkit-scrollbar {
        display: none;
      }

      scrollbar-width: none;

      .line1 {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 4px;
      }

      .line2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        margin-bottom: 24px;

        .sum {
          padding: 9px 16px;
          color: $primary-font-color;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          background: #fefefe;
          box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.02);
          border-radius: 46px;
        }
      }

      .list {
        width: 100%;
      }
    }
  }
}