@import '../../assets/styles//mixin.scss';
@import '../../assets/styles/const.scss';

.container,
.cardTypeContainer {
  margin-bottom: 24px;
  margin-right: 24px;
  transition: all 0.4s ease 0s;
  position: relative;
  width: 228px;
  height: 300px;
  background-color: #eee;

  border-radius: $primary-border;

  &:nth-of-type(4n + 4) {
    margin-right: 0;
  }

  p {
    margin-bottom: 0;
    line-height: 22px;
    letter-spacing: 2px;
  }

  .transfer {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 17px;
    top: 16px;
    z-index: 19;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

.container {
  cursor: pointer;
}

.cover,
.coverNoTime {
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  text-align: initial;
  font-size: 18px;
  background-color: #fff;
  border-radius: $primary-border;
}

.cover {
  padding: 36px 12px 5px 12px;
}

.coverNoTime {
  padding: 42px 12px 4px 12px;

  .name {
    margin-bottom: 10px !important;
  }
}

.name,
.price,
.highPriceContainer {
  color: #222;
}

.name {
  @include no-wrap;

  margin-top: 4px !important;
  margin-bottom: 4px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.price {
  display: flex;
  justify-content: space-between;

  .priceTitle {
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #222222;
  }

  .priceNumber {
    padding-right: 12px;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #727993;
  }
}

.highPriceContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;

  .hightPriceContent {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #727993;

    .num {
      max-width: 95px;
      @include no-wrap();
    }
  }
}

.cardOption {
  width: 158px;
  height: 32px;
  margin: 0 auto 9px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 16px;
  line-height: 32px;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.clockText {
  position: absolute;
  top: 18px;
  right: 11px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 2px;
  text-align: right;

  > img {
    vertical-align: middle;
  }

  > span {
    vertical-align: middle;
    margin-left: 5px;
  }
}

.my-player {
  height: 100% !important;
}

.video-react.video-react-fluid {
  height: 100% !important;
}

.editModal {
  width: 960px;
  height: 542px;
}

.imgContainer,
.cardTypeImgContainer {
  position: absolute;
  overflow: auto;
  z-index: 9;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 228px;
  background-color: #e5e5e8;
  border-radius: $primary-border;

  .img {
    z-index: 9;
    background-color: #f8f8f8;
    object-fit: contain;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.cardTypeImgContainer {
  // opacity: 0.7;
}

:global {
  .video-react-controls-enabled {
    padding-top: 0 !important;
  }
}
