@import '../../../../assets/styles/const.scss';

.container {
  width: 1168px;
  margin: 0 auto;

  .cards,
  .notPutcards {
    min-height: 400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }

  .notPutcards {
    width: 984px;
    margin-bottom: 110px;
  }

  .divider {
    margin-top: 16px;
    margin-bottom: 40px;
    border: 1px solid #dedcdc;
  }

  .collectsContainer {
    display: flex;
    flex-wrap: wrap;
    width: 1170px;
    margin: 0 auto;
  }

  .descriptionCon {
    width: 984px;
    margin: $primary-margin auto 28px;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #222222;
    opacity: 0.7;
    text-align: left;
  }

  .divider {
    border: 1px solid #DEDCDC;
  }

  .content {
    width: 984px;
    margin: 0 auto;
  }

  .nftBack {
    margin-top: $second-margin;
    margin-bottom: $second-margin;
    padding-left: 25px;
    opacity: 0.7;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 4px;
    text-align: left;
    cursor: pointer;
    background: url('../../../../assets/images/back.svg') left center no-repeat;

    &:hover {
      color: #1434e1;
      background-image: url('../../../../assets/images/back-hover.png');
    }
  }


}