.SellOrBuy {
  width: 574px;
  margin: 0 auto 0;

  .createContent {
    width: 100%;
    display: flex;

    box-sizing: border-box;
    margin: 0 auto;

    .collectionSection {
      width: 100%;
      display: flex;
      flex-direction: column;

      .types {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        width: 100%;
        padding-top: 16px;
        box-sizing: border-box;
        border-top: 1px solid #dedcdc;
        overflow-x: auto;

        .collectableSelect {
          position: absolute;
          top: 0;
          right: -2px;
        }

        .typesTitleBox {
          display: flex;
          justify-content: space-between;
        }

        .typesTitle {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -1px;
          color: #222222;
          margin-bottom: 24px;
        }

        .passButton {
          font-family: Fira Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          /* identical to box height, or 129% */

          text-align: center;
          letter-spacing: 4px;
          text-transform: uppercase;

          /* Theme 1 */

          color: #1434e1;

          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        }

        .cards {
          display: flex;
          width: max-content;

          .card {
            position: relative;
            width: 274px;
            height: 98px;
            background: #ffffff;
            border: 2px solid #dedcdc;
            box-sizing: border-box;
            border-radius: 12px;
            padding: 24px 40px;
            box-sizing: border-box;
            margin-right: 24px;
            cursor: pointer;

            .title {
              font-weight: 500;
              font-size: 20px;
              line-height: 28px;
              letter-spacing: -1px;
              color: #222222;
            }

            .value {
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              color: rgba(34, 34, 34, 0.7);
            }
          }

          .active {
            border-color: #222;
          }

          .card:last-child {
            margin-right: 0;
          }
        }
      }

      .infos {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 32px;
        box-sizing: border-box;

        .price {
          display: flex;
          flex-direction: column;
          margin-bottom: 25px;

          .priceEdit {
            display: flex;

            .inputSection {
              width: 248px;
              margin-right: 16px;
            }

            .category {
              display: flex;
              align-items: flex-end;

              .text {
                width: 113px;
                text-align: left;
                font-weight: 500;
                font-size: 14px;
                line-height: 32px;
                letter-spacing: 4px;
                text-transform: uppercase;
                color: #222222;
              }

              .categorySelect,
              .selectStyle {
                width: 124px;
                height: 32px;
              }

              .selectStyle {
                :global {
                  .ant-select {
                    width: 124px;
                    height: 32px;

                    &-selector {
                      height: 32px !important;
                      padding: 8px 12px !important;
                    }
                  }
                }
              }
            }
          }

          .tips {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #222222;
            opacity: 0.3;
            margin-top: 24px;
          }
        }

        .divider {
          margin-top: 7px;
          margin-bottom: 24px;

          border: 1px solid #dedcdc;
        }

        .submit {
          width: 222px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          background: #4737ff;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          letter-spacing: 4px;
          text-transform: uppercase;
          color: #ffffff;
          margin: 0px auto 20px;

          &:hover {
            cursor: pointer;
            opacity: 0.7;
          }
        }
        .btnDisabled {
          width: 222px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          background: #949494;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          letter-spacing: 4px;
          text-transform: uppercase;
          color: #ffffff;
          margin: 0px auto 20px;

          &:hover {
            opacity: 0.8;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .modalContent {
    width: 100%;
    display: flex;
    flex-direction: column;

    .priceDiv {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 24px 32px;
      box-sizing: border-box;
      border-bottom: 1px solid #dedcdc;

      .priceTitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -1px;
        color: #222222;
        margin-bottom: 20px;
      }

      .priceSection {
        display: flex;
        justify-content: flex-end;
        font-weight: 600;
        font-size: 48px;
        line-height: 56px;
        text-align: right;
        letter-spacing: -1px;
        color: #222222;

        .priceUint {
          margin-left: 10px;
        }
      }

      .priceTips {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        color: #142222;
        opacity: 0.7;
        margin-top: 24px;
      }

      .priceSelect {
        margin-left: auto;
        margin-top: 16px;

        .selectStyle {
          width: 124px;
          height: 32px;
        }

        .selectStyle {
          :global {
            .ant-select {
              width: 124px;
              height: 32px;

              &-selector {
                height: 32px !important;
                padding: 8px 12px !important;
              }
            }
          }
        }
      }

      .miniTips {
        margin-top: 4px;
        display: flex;
        justify-content: flex-end;

        .bolderText {
          font-weight: 500;
          margin-left: 5px;
        }
      }
    }

    .priceDetailed {
      display: flex;
      flex-direction: column;
      padding: 8px 24px;
      box-sizing: border-box;

      .detailedItem {
        width: 100%;
        height: 18px;
        margin-top: 16px;
        display: flex;
        justify-content: space-between;

        .detailName {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -1px;
          color: #484848;
        }

        .detailPrice {
          display: flex;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          text-align: right;
          letter-spacing: -1px;
          color: #484848;

          .detailUnit {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
.banCrc721 {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -1px;
  text-align: center;
  color: #ec5e4e;
}
