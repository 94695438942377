// 单行文本超出显示省略号
@mixin no-wrap() {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 多行文本超出显示省略号
@mixin no-wrap-multi($linNum) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $linNum;
  overflow: hidden;
  text-overflow: ellipsis;
}

// 1px
@mixin one-px-border($borderColor: #e0e0e0, $direction: bottom) {
  position: relative;

  /* stylelint-disable-line */
  &::after {
    position: absolute;

    @if ($direction==bottom) {
      bottom: 0;
    } @else {
      top: 0;
    }

    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background-color: $borderColor;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      transform: scaleY(0.5);
    }

    @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
      transform: scaleY(0.333);
    }
  }
}

// 2x 3x 背景图片
@mixin bg-image($url) {
  background-image: url("$url + '@2x.png'");

  @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
    background-image: url("$url + '@3x.png'");
  }
}

@mixin defaultPicLoad($w, $h) {
  background: #fff url('../../../assets/images/default.png') center / $w $h no-repeat;
}

// flex-水平垂直居中
@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

// flex-垂直不居中
@mixin flex-align-center() {
  display: flex;
  // justify-content: center;
  align-items: center;
}

// flex-垂直两端对齐
@mixin flex-space-between() {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

// flex-垂直布局，水平居中
@mixin flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// btn样式重置
@mixin btn {
  &:hover,
  &:active,
  &:focus {
    border: none;
    outline: none;
    opacity: 0.8;
    color: currentColor;
    background-color: inherit;
  }
}
