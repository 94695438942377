$primary-font-color: #222;
$second-font-color: #75777C;
$txt-color: #484848;
$active-color: #1434E1;
$border-color: #DEDCDC;
$primary-bg: #2183e8; // 主背景色（蓝色）
$color-green: #44d7b6; // 绿色
$color-orange: #ff8b3e; // 橙色
$color-success: #f28f1d; // 成功色
$color-error: #ec5e4e; // 错误色
$color-warning: #f28f1d; // 警告色
$color-notice: #f28f1d; // 提示色
$font-size-base: 18px; // 主字号
$txt-color1: #333; // 字体色1
$txt-color2: #555; // 字体色2
$txt-color3: #999; // 字体色3
$txt-color4: #ccc; // 字体色4
$neutral-color1: #e5e5e5; // 中性色1
$neutral-color2: #ededed; // 中性色2
$neutral-color3: #f8f8f8; // 中性色3
$border-radius-base: 8px; // 组件/浮层圆角
$box-shadow-base: 0 2px 6px 0 rgba(0, 0, 0, 0.12); // 浮层阴影
$primary-border:12px; //圆角1
$primary-margin:32px; //主要间距
$second-margin:24px; //次间距