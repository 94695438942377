body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
/* 字体 */
@font-face {
  font-display: auto;
  font-family: Fira Sans;
  font-weight: normal;
  src: url('./assets/fonts/FiraSans-Regular.ttf');
}

@font-face {
  font-display: auto;
  font-family: Fira Sans;
  font-weight: 500;
  src: url('./assets/fonts/FiraSans-Medium.ttf');
}

@font-face {
  font-display: auto;
  font-family: Fira Sans;
  font-weight: 600;
  src: url('./assets/fonts/FiraSans-SemiBold.ttf');
}
body {
  color: #555;
  font-family: Fira Sans, 'PingFang SC', 'Microsoft YaHei', 'HelveticaNeue', Helvetica, Arial, sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  background-color: #f4f4f4 !important;
}

td,
th,
caption {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

a {
  color: #555;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  border: none;
}

ol,
ul,
li {
  list-style: none;
}

input,
textarea,
select,
button {
  font: 14px Verdana, Helvetica, Arial, sans-serif;
}

table {
  border-collapse: collapse;
}

html {
  overflow-y: scroll;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  *zoom: 1;
}