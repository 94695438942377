.baseInput {
  position: relative;
  width: 100%;
  .textSection {
    display: flex;
    justify-content: flex-start;
    .required {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 4px;
      text-transform: uppercase;
      color: #1434e1;
    }
    .text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 4px;
      text-transform: uppercase;
      color: rgba(34, 34, 34, 0.7);
    }
  }
  .input {
    width: 100%;
    height: 22px;
    display: flex;
    border-bottom: 1px solid #222222;
    margin-top: 10px;
    padding-bottom: 4px;
    box-sizing: border-box;
    .inputItem {
      input {
        width: 100%;
        height: 100%;
        background: none;
        outline: none;
        border: none;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #222;
        text-align: center;
      }
      ::-webkit-input-placeholder {
        color: rgba(34, 34, 34, 0.3);
      }
      ::-moz-placeholder {
        color: rgba(34, 34, 34, 0.3);
      } /* firefox 19+ */
      :-ms-input-placeholder {
        color: rgba(34, 34, 34, 0.3);
      } /* ie */
      input:-moz-placeholder {
        color: rgba(34, 34, 34, 0.3);
      }
    }
    .inputItem:first-child {
      position: relative;
      margin-right: 8px;
      padding-right: 4px;
      box-sizing: border-box;
    }
    .inputItem:last-child {
      padding-left: 4px;
      box-sizing: border-box;
    }
    .inputItem:first-child::before {
      content: "";
      display: block;
      width: 8px;
      height: 2px;
      background: #000;
      position: absolute;
      right: -8px;
      top: 10px;
    }
  }
  .error {
    input {
      border-color: #c8020e;
    }
    ::-webkit-input-placeholder {
      color: #c8020e;
      opacity: 0.7;
    }
    ::-moz-placeholder {
      color: #c8020e;
      opacity: 0.7;
    } /* firefox 19+ */
    :-ms-input-placeholder {
      color: #c8020e;
      opacity: 0.7;
    } /* ie */
  }
}
