.createHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  .miniTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #222222;
    opacity: 0.7;
    margin-top: 32px;
    margin-bottom: 12px;
  }

  .titileDiv {
    display: flex;

    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 50px;
      letter-spacing: -1px;
      color: #222222;
    }

    .passButton {
      width: 177px;
      height: 32px;
      background: rgba(0, 0, 0, 0.9);
      border-radius: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 4px;
      text-transform: uppercase;
      color: #fff;
      cursor: pointer;
      margin: auto 0 auto 30px;
    }
  }
}