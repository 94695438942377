.noData {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -1px;
  color: #222222;
}
