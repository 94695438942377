.container {
  display: flex;
  justify-content: space-between;
}

.editModal {
  width: 960px !important;
  height: 542px;
}

.title {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -1px;
  color: #222222;
}

.byContainer {
  display: flex;
  color: #222222;
  opacity: 0.7;
  width: 232px;
  margin-right: 22px;
  text-align: initial;

  .user {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 10px;
    width: 200px;
    color: #1434e1;
  }
}

.left {
  width: 300px;
  margin-left: 60px;
  margin-right: 110px;

  .idInfo {
    width: 178px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #222222;
    opacity: 0.7;
  }

  .leftContent {
    padding-left: 48px;
  }

  .leftImage {
    margin-top: 6px;
    margin-bottom: 10px;

    > .img {
      width: 248px;
      height: 292px;
      border-radius: 5px;
    }
  }
}

.right {
  color: #222222;

  .transferLabel {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -1px;
  }

  .wallet {
    margin-top: 16px;
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  .desc {
    font-size: 14px;
    line-height: 18px;
    opacity: 0.3;
  }

  .buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 185px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;

    .neverMind {
      color: #222222;
      letter-spacing: 4px;
    }

    .confirm {
      width: 222px;
      height: 48px;
      color: #fff;
      letter-spacing: 4px;
      background: #4737ff;
      border: none;
      outline: none;

      &:hover {
        opacity: 0.8;
      }
    }

    .disableConfirm {
      width: 222px;
      height: 48px;
      color: #fff;
      letter-spacing: 4px;
      background-color: rgba(34, 34, 34, 0.3);

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
