.border {
  width: 100%;
  padding-bottom: 23px;
  border-bottom: 1px solid #dedcdc;
  margin: 24px 0;
}

.container {
  display: flex;
  align-items: center;

  width: 1170px;
  margin: 0 auto;
  color: #fff;
  font-weight: 500;

  .image {
    position: relative;
    width: 120px;
    height: 120px;
    margin-right: 60px;

    .imageContent {
      width: 120px;
      height: 120px;
    }

    > img {
      border-radius: 5px;
    }

    .cover {
      position: absolute;
      bottom: 0;
      width: 120px;
      height: 32px;
      line-height: 32px;
      letter-spacing: 2px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.016) 0%,
        rgba(0, 0, 0, 0.6) 75.43%,
        rgba(0, 0, 0, 0.72) 100%
      );
      backdrop-filter: blur(10px);
    }
  }

  .type {
    width: 136px;
    height: 32px;
    margin-right: 70px;
    background: rgba(34, 34, 34, 0.1);
    border-radius: 16px;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 4px;
    text-align: center;
  }

  .ids {
    line-height: 22px;
    color: #222222;
    margin-right: 60px;
  }

  .byContainer {
    display: flex;
    color: #222222;
    opacity: 0.7;
    width: 232px;
    margin-right: 22px;
    text-align: initial;

    .user {
      display: inline-block;
      margin-left: 10px;
      width: 200px;
      color: #1434e1;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .time {
    width: 174px;
    margin-right: 143px;
    color: #222222;
    opacity: 0.7;
  }

  .number {
    line-height: 22px;
    color: #222222;
  }
}
