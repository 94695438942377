@import '../../../../assets/styles/const.scss';

.tag {
  display: flex;
  align-items: center;
  padding: 13px 12px 13px 14px;
  margin-left: 16px;
  color: $primary-font-color;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  background: #FEFEFE;
  box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.02);
  border-radius: 46px;

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}