@import '../../../../assets/styles/const.scss';

.checkList {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  .item {
    display: flex;
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }

    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    p {
      margin: 0 0 0 9px;
      font-size: 12px;
      line-height: 16px;
      color: rgba(34, 34, 34, 0.7);

      span {
        color: $active-color;
        cursor: pointer;
      }
    }
  }

}