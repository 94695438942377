@import '@/assets/styles/index.scss';

.myFooter {
  width: 100%;
  background-color: #1a1a1a;
  color: rgba($color: #fff, $alpha: 0.6);
  font-size: 16px;
  line-height: 32px;

  .conBox {
    width: 100%;
    padding: 0 70px;
    margin: 0 auto;

    .copyRight {
      display: flex;
      width: 100%;
      height: 48px;
      padding: 10px 42px 60px;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      line-height: 48px;
      @include one-px-border(rgba(255, 255, 255, 0.3), top);

      .copy {

        font-family: Fira Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #FFFFFF;
        opacity: 0.4;
        margin-right: 16px;
      }

      .line {
        width: 1px;
        height: 12px;
        background-color: #fff;
        margin: 19px 16px 0;
      }

      .item {
        font-family: Fira Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #ffffff;
        mix-blend-mode: normal;
        opacity: 0.4;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }

    .con {
      display: flex;
      align-items: center;
      padding: 62px 42px;

      .logo {
        width: 166px;
        height: 20px;
      }

      .center {
        display: flex;
        justify-content: space-between;
        width: 720px;
        margin-left: 48px;
      }

      .link {
        flex: 1;
        display: flex;
        justify-content: space-between;
      }

      .right {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 203px;

        .wechatBox {
          position: absolute;

          right: -43px;
          top: -92px;
        }

        .emailBox {
          position: absolute;
          width: 216px;
          height: 46px;
          right: -55px;
          top: -41px;
          text-align: center;
          background-image: url('../../assets/images/email-box.png');
          background-repeat: no-repeat;
          background-size: cover;

          p {
            height: 46px;
            line-height: 46px;
            font-family: Fira Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            color: #222222;
          }
        }
      }

      .item {
        font-family: Fira Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #ffffff;
        mix-blend-mode: normal;
        opacity: 0.4;
        margin: 0;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }

      .itemImg {
        width: 22px;
        height: 15px;
        opacity: 0.4;
        z-index: 999;

        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }
}