@import '../../../../assets/styles/const.scss';
@import '../../../../assets/styles/mixin.scss';

.infos {
  margin-top: 32px;

  &Item {
    padding: 24px 32px;
    @include one-px-border($border-color, top);

    .owner {
      display: flex;
      align-items: center;
      width: 310px;
      height: 88px;
      padding: 12px 31px 12px 12px;
      background: #fefefe;
      box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.02);
      border-radius: 46px;

      .avatar {
        width: 64px;
        height: 64px;
        margin-right: 36px;
        border-radius: 50%;
      }

      .txtBox {
        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
        color: $primary-font-color;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;

        span {
          &:first-child {
            @include no-wrap();
          }

          &:last-child {
            margin-top: 6px;
            font-size: 18px;
            opacity: 0.7;
          }
        }
      }
    }
  }

  .tabs {
    margin-bottom: 24px;
  }

  .infoTxt {
    font-size: 18px;
    line-height: 28px;
    color: $primary-font-color;
    opacity: 0.7;
  }

  .chainInfo {
    display: flex;
    flex-direction: column;

    &Item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }

      .label {
        color: #484848;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -1px;
      }

      .txt {
        color: $primary-font-color;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        opacity: 0.7;
      }

      .address {
        color: #1434e1;
        cursor: pointer;
        width: 122px;
        @include no-wrap();
      }
    }
  }

  .attrs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -15px;

    &Item {
      padding: 13px 29px;
      margin-right: 15px;
      margin-bottom: 15px;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: $primary-font-color;
      opacity: 0.7;
      border: 1px solid $border-color;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
}