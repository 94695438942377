.privacyPage {
  width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .pageTitle {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #333;
    line-height: 30px;
    margin-top: 40px;
    margin-bottom: 36px;
  }
  .contentItem {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    .itemTitle {
      font-weight: bold;
      color: #333;
      font-size: 20px;
      line-height: 30px;
    }
    .itemSpanList {
      display: flex;
      flex-direction: column;
      .spanContent {
        font-weight: normal;
        color: #333;
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
  .tips {
    text-align: left;
    font-weight: normal;
    color: #333;
    font-size: 16px;
    line-height: 30px;
  }
  .team {
    text-align: right;
    font-size: 20px;
    font-weight: 500;
    color: #333;
    line-height: 30px;
    margin-bottom: 40px;
  }
}
