.safeModal {
  width: 800px !important;
  color: #222;

  .content {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      text-align: center;
    }

    .tips {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      margin-top: 24px;
      margin-bottom: 30px;
    }

    .con {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
