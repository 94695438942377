.container {
  width: 1168px;
  margin: 30px auto 0;
  padding-bottom: 1px;

  .userInfo {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 4px;
    opacity: 0.7;
    margin-bottom: 12px;
  }

  .title {
    margin: 0;
    color: #222;
    font-weight: 600;
    font-size: 40px;
    letter-spacing: -1px;
  }

  .selectBox {
    width: 100%;
    text-align: center;
    margin: 16px auto 0;
  }


  .selectList {
    width: 100%;
    margin-left: auto;

    ul {
      justify-content: flex-end;
    }
  }
}