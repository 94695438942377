.container {
  display: flex;
  justify-content: center;
  margin: 40px 32px 0;
  padding-bottom: 56px;
  height: 838px;
  border-bottom: 1px solid #dedcdc;

  .left {
    position: relative;
    height: 760px;
    width: 487px;
    padding-top: 66px;
    margin-right: 126px;
  }

  .title {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 60px;
    line-height: 70px;
    letter-spacing: -1px;
    color: #222222;
  }

  .desc {
    font-size: 18px;
    line-height: 28px;
    opacity: 0.7;
  }

  .image {
    width: 568px;
    // height: 758px;
  }

  .discover {
    position: absolute;
    bottom: 106px;
    width: 160px;
    height: 48px;
    background-color: #4737ff;
    color: #fff;
    letter-spacing: 4px;
    border: none;
    outline: none;

    &:hover {
      opacity: 0.8;
      color: currentColor;
    }
  }

  .scroll {
    position: absolute;
    bottom: 32px;
    display: flex;
    align-items: center;


    .linesContainer {
      display: flex;
      width: 278px;
    }

    .line,
    .lineActive {
      flex: 1;
      height: 5px;
      background: rgba(34, 34, 34, 0.7);

      &:hover {
        cursor: pointer;
      }
    }

    .line {
      opacity: 0.2;

      &:hover {
        opacity: 0.7;
      }
    }

    .lineActive {
      opacity: 1;
    }

    .lineTitle,
    .lineTitleActive {
      display: block;
      width: 21px;
      font-size: 18px;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }

    .lineTitle {
      opacity: 0.5;
    }

    .lineActive {
      opacity: 1;
    }
  }
}

.fadeIn {
  display: block;
  animation: fadeIn 1s linear;
}

.fadeOut {
  display: none;
  animation: fadeOut 1s linear;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}