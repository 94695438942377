@import '../../assets/styles/const.scss';

.container {
  overflow: hidden;
  position: relative;
  width: 572px;
  height: 228px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: center;
  background-color: gray;
  margin-right: $second-margin;
  margin-bottom: $second-margin;
  border-radius: $primary-border;

  &:nth-of-type(2n+2) {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
  }

  .text {
    margin: 40px 0 0 48px;
    font-weight: 600;
    font-size: 60px;
    letter-spacing: -1px;
    color: #ffffff;
    text-align: left;
  }

  .icon {
    position: absolute;
    right: 32px;
    bottom: 24px;
  }

  .coinEdit {
    position: absolute;
    top: 32px;
    right: 32px;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}