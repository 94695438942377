@import '../../../../assets/styles/const.scss';

.container {
  margin-top: 12px;

  .selectList {
    display: flex;
    justify-content: flex-end;
  }

  .homeExplore {
    display: flex;
    overflow: hidden;
    height: 352px;
    width: 268px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-right: -32px;
  }

  .title {
    margin: 0;
    font-weight: 600;
    font-size: 40px;
    color: $primary-font-color;
  }
}