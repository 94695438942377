.baseInput {
    position: relative;
    width: 100%;

    .textSection {
        display: flex;
        justify-content: flex-start;

        .required {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: #1434e1;
        }

        .text {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: rgba(34, 34, 34, 0.7);
        }
    }

    .input {
        width: 100%;

        input {
            width: 100%;
            height: 100%;
            background: none;
            outline: none;
            border: none;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #222;
            border-bottom: 1px solid #222222;
        }

        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0;
        }

        /*在firefox下移除input[number]的上下箭头*/
        input[type='number'] {
            -moz-appearance: textfield;
        }

        ::-webkit-input-placeholder {
            color: rgba(34, 34, 34, 0.3);
        }

        ::-moz-placeholder {
            color: rgba(34, 34, 34, 0.3);
        }

        /* firefox 19+ */
        :-ms-input-placeholder {
            color: rgba(34, 34, 34, 0.3);
        }

        /* ie */
        input:-moz-placeholder {
            color: rgba(34, 34, 34, 0.3);
        }
    }

    .error {
        input {
            border-color: #c8020e;
        }

        ::-webkit-input-placeholder {
            color: #c8020e;
            opacity: 0.7;
        }

        ::-moz-placeholder {
            color: #c8020e;
            opacity: 0.7;
        }

        /* firefox 19+ */
        :-ms-input-placeholder {
            color: #c8020e;
            opacity: 0.7;
        }

        /* ie */
    }
}

.textarea {
    background-color: inherit;
    height: 78px;
    width: 100%;

    &:focus-visible {

        border-color: inherit;
        outline: 0;
    }
}