@import '../../../../assets/styles/const.scss';

.container {
    .title {
        margin-top: $second-margin;
        font-weight: 600;
        font-size: 40px;
        color: $primary-font-color;
    }

    .trends {
        margin: 0;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 4px;
    }

    .desc {
        margin-top: 17px;
        font-size: 18px;
        line-height: 28px;
        opacity: 0.7;
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: $second-margin;
    }
}