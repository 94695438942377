@import '../../../../assets/styles/const.scss';
@import '../../../../assets/styles/mixin.scss';

.priceInfosItem {
  display: flex;
  flex-direction: column;
  color: $primary-font-color;
  font-weight: 500;
  letter-spacing: -1px;

  .label {
    font-size: 20px;
    line-height: 24px;
  }

  .num {
    margin: 16px 0 0;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
  }

  .txt {
    margin: 24px 0 0;
    color: $second-font-color;
    font-size: 20px;
    line-height: 24px;
  }

  .timeLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 252px;

    .num {
      flex: 1;
    }
  }
}
