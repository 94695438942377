@import '../../../../assets/styles/const.scss';

.list {
  width: 100%;
  /*隐藏滚轮*/
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  .tabLine {
    display: flex;
    align-items: center;

    .icon {
      width: 16px;
      height: 14px;
      margin-right: 8px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .content {
    width: 100%;
    max-height: 560px;
    padding-top: 22px;

    .con {
      display: flex;
      flex-wrap: wrap;
      overflow: auto;
      min-height: 30px;
    }
  }
  .noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: -1px;

    .txt1 {
      margin: 115px 0 0;
      color: $primary-font-color;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
    }

    .txt2 {
      margin: 29px 0 0;
      color: $active-color;
      font-size: 14px;
      line-height: 18px;
      opacity: 0.7;
    }
  }
}
