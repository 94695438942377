.imgInfo {

  display: flex;
  flex-direction: column;


  .imgSection {
    width: 572px;
    height: 228px;
  }
}