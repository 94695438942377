@import '../../assets/styles/const.scss';

.baseSelect {
  :global {
    .ant-select {
      color: $primary-font-color;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 4px;



      &-selector {
        height: 40px !important;
        padding: 13px 33px 9px 24px !important;
        border: 1px solid rgba(34, 34, 34, 0.3);
        border-radius: 0 !important;
        background-color: inherit !important;
        box-sizing: border-box;

        &:hover {
          // border-color: inherit !important;

        }
      }

      &-arrow {
        right: 21px;
        height: 6px;
        color: $primary-font-color;
      }

      &-selection-item {
        line-height: 16px !important;
      }
    }

    // .ant-select-open {
    //   .ant-select-selector {
    //     border-color: inherit !important;
    //     box-shadow: inherit !important;
    //   }
    // }

  }
}

:global {
  .ant-select-item-option {
    border-bottom: 1px solid #DEDCDC;
    margin: 0 12px;
    padding: 12px !important;
    opacity: 0.7 !important;

    &:last-child {
      border-bottom: 0;
    }
  }

  .ant-select-item-option-selected,
  .ant-select-item-option-active {
    background-color: inherit !important;
    opacity: 1 !important;
  }

  .ant-select-item-option-active {
    color: #1434E1 !important;
  }
}