@import '../../assets/styles/const.scss';
@import '../../assets/styles/mixin.scss';

.detailPage {
  width: 1264px;
  min-height: 1300px;
  margin: 0 auto;

  .goBack {
    margin-right: 6px;
    font-size: 22px;
    width: 20px;
    color: #1434e1;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .titleLine {
    display: flex;
    align-items: center;
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;

    .left {
      width: 552px;

      .imgBox {
        position: relative;
        display: flex;

        .operate {
          z-index: 19;
          position: absolute;
          left: 7px;
          display: flex;
          flex-direction: column;
          margin-right: 56px;

          &Item {
            width: 48px;
            height: 48px;
            margin-top: 24px;
            cursor: pointer;

            &:first-child {
              margin-top: 0;
            }
          }
        }

        .imgCon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 504px;
          height: 504px;
          margin-left: 32px;
          background-color: #fff;
          box-shadow: -10px 10px 150px 4px rgba(0, 0, 0, 0.15);
          border-radius: 12px;

          .img {
            object-fit: contain;
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }

    .right {
      width: 600px;

      .unit {
        margin-left: 5px;
        font-family: Fira Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -1px;
        color: #75777c;
      }

      &Item {
        padding: 32px 0px;
        @include one-px-border($border-color, top);

        .priceInfos {
          display: flex;
          justify-content: space-between;

          &Item {
            display: flex;
            flex-direction: column;
            color: $primary-font-color;
            font-weight: 500;
            letter-spacing: -1px;

            .label {
              font-size: 20px;
              line-height: 24px;
            }

            .num {
              margin: 16px 0 0;
              font-weight: 600;
              font-size: 48px;
              line-height: 56px;
            }

            .txt {
              margin: 24px 0 0;
              color: $second-font-color;
              font-size: 20px;
              line-height: 24px;
            }

            .timeLine {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 252px;

              .num {
                flex: 1;
              }
            }
          }
        }

        .notSell {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: $primary-font-color;
          letter-spacing: -1px;

          p {
            margin: 0;
          }

          .txt1 {
            margin: 16px 0 0;
            font-weight: 600;
            font-size: 48px;
            line-height: 56px;
          }

          .txt2 {
            margin: 24px 0 0;
            color: $second-font-color;
          }
        }
      }

      .btnLine {
        display: flex;
        align-items: center;

        .btn:first-child {
          background: #4737ff;
          color: #fff;
        }
      }

      .btn {
        width: 222px;
        height: 48px;
        margin-top: 54px;
        font-weight: 500;
        font-size: 14px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 4px;
        cursor: pointer;
        background-color: transparent;
        color: #484848;

        &:hover {
          opacity: 0.8;
        }
      }
      .btnDisabled {
        width: 222px;
        height: 48px;
        margin-top: 54px;
        font-weight: 500;
        font-size: 14px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 4px;
        background-color: #949494;
        color: #ffffff;

        &:hover {
          opacity: 0.8;
          cursor: not-allowed;
        }
      }
    }
  }
}

.hideInfoLabel {
  color: #1434e1;
  line-height: 17px;

  &:hover {
    color: #1434e1;
    opacity: 0.7;
  }
}

.hideModalInfoContent {
  height: 504px;
  width: 504px;
  // padding: 24px;
}

.lock {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.hideInfoBox {
  display: flex;
  align-items: center;
}

.pageDesc {
  display: flex;
  align-items: center;
}
.banCrc721 {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -1px;
  color: #ec5e4e;
}
