@import '../../../../assets/styles/const.scss';

.list {
  width: 100%;
  min-height: 1660px;

  .itemContainer {
    display: flex;
  }

  /*隐藏滚轮*/
  ::-webkit-scrollbar {
    display: none;
  }


  .con {
    display: flex;
    flex-wrap: wrap;
  }

  .listNoData {
    margin: 0 auto;
  }

  .card {
    width: 228px;
    height: 300px;
    margin-right: $second-margin;

    div {
      &:first-child {
        height: 228px;
      }
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}