@import '../../assets//styles/mixin.scss';

.header {
  position: fixed;
  z-index: 998;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f4f4f4;
  margin: 0 auto;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 48px);
  padding: 23px 74px;

  border-bottom: 1px solid #dedcdc;

  .right,
  .left {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 160px;
    height: 20px;

    @include btn();

    &:hover {
      cursor: pointer;
    }
  }

  .search {
    width: 356px;
    margin-left: 40px;
    border-radius: 24px;
    background: #fcfcfc;
    border: 1px solid #dedcdc;

    &:hover {
      border-color: #222 !important;
    }
  }

  .marketplace {
    width: 168px;
    font-size: 14px;
    color: #333333;
    line-height: 40px;
    letter-spacing: 4px;
    text-align: center;
  }

  .create {
    width: 122px;
    height: 40px;
    margin-left: 16px;
    margin-right: 16px;
    border: 1px solid #222222;
    box-sizing: border-box;
    border-radius: 20px;
    border: 1px solid #222222;
    background-color: inherit;

    @include btn();

    &:hover {
      border: 1px solid #222222;
    }
  }

  .stringCode {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 142px;
    height: 40px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid #dedcdc;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 13px;
    outline: none;

    @include btn();

    &:hover,
    &:active {
      border: 1px solid #dedcdc !important;
    }
  }

  .connectWallet {
    padding-top: 0;
    padding-bottom: 0;
    width: 142px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #dedcdc;
    border-radius: 20px;
    cursor: pointer;
    outline: none;

    &:hover {
      opacity: 0.8;
      color: currentColor;
      border: 1px solid #dedcdc;
    }

    span {
      width: auto;
      height: 100%;
      font-weight: 500;
      font-size: 14px;
      color: #222222;
      background-color: #fff;
    }
  }

  .language {
    margin-left: 17px;
    margin-right: 18px;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .dot {
    display: block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
    background: #7cd77b;
  }

  .mySpace {
    width: 32px;
    height: 32px;
    margin-left: 16px;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

// @media screen and (max-width: 1360px) {
//   .container {
//     .icon {
//       margin-left: 26px;
//       margin-right: 31px;
//     }

//     .right {
//       margin-left: 17%;
//     }
//   }
// }
