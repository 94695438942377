.contentContainer {
    width: 1168px;

    margin: 0 auto 209px;


    .various {
        margin-top: 56px;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 4px;
        opacity: 0.7;
    }
}