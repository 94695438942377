@import '../../assets/styles/mixin.scss';

.payModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  display: flex;
  justify-content: center;

  .titleBox {
    display: flex;
    align-items: center;
  }

  .bc {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    background: #222222;
    opacity: 0.2;
  }

  .payModalContent {
    width: 898px;
    max-height: max-content;
    margin: auto;
    background-color: #fff;
    border-radius: 12px;
    z-index: 10;
    padding: 0 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .modalTitle {
      width: 100%;
      height: 76px;
      line-height: 76px;
      padding: 0 28px;
      box-sizing: border-box;
      border-bottom: 1px solid #dedcdc;
      font-weight: 600;
      font-size: 24px;
      letter-spacing: -1px;
      color: #222222;
    }

    .modalInfo {
      padding: 24px 32px 32px 28px;
      box-sizing: border-box;
      display: flex;

      .modalLeft,
      .modalRight {
        display: flex;
        flex-direction: column;
      }

      .modalLeft {
        width: calc(100% - 444px);

        .titles {
          display: flex;
          margin-bottom: 32px;

          .subTitle,
          .horizontalBar {
            @include no-wrap;

            max-width: 300px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: #222222;
            opacity: 0.7;
          }
        }

        .miniTitle {
          padding-left: 10px;
          box-sizing: border-box;
        }

        .imgs {
          width: 268px;
          height: 268px;
          border-radius: 12px;
          // box-shadow: 0 1px 1px rgb(0 0 0 / 20%);

          .newStyles {
            width: 100%;
            height: 100%;
          }

          .childStyles {
            top: auto;
            bottom: 0;
          }
        }

        .addrs {
          width: 240px;
          margin-top: 32px;
          // margin: 18px auto 0;

          .addr {
            width: 130px;
            display: flex;
            justify-content: flex-start;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #222222;

            .blueColor {
              color: #1434e1;
              margin-left: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .modalRight {
        width: 444px;
        display: flex;
        flex-direction: column;

        .modalChild {
          padding-top: 51px;
          min-height: 330px;
        }

        .modalButtons {
          display: flex;
          justify-content: space-between;
          margin-top: 24px;

          .buttons {
            width: 222px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            cursor: pointer;
          }

          .cancelButton {
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: #222222;
          }

          .comfirmButton {
            background-color: #4737ff;
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: #ffffff;
          }

          .comfirmButtonDisable {
            background-color: rgba(34, 34, 34, 0.3);
            cursor: no-drop;
          }
        }
      }
    }
  }
}